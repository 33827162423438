import colors from '!!@homebase/sass-variable-loader!./_colors.scss';

export const cloverColors = colors;

export const cloverThemeVars = {
  '--primary100': colors.purple100,
  '--primary300': colors.purple300,
  '--primary400': colors.purple400,
  '--primary500': colors.purple500,
  '--primary700': colors.purple700,
  '--primary900': colors.purple900,
  '--mono0': colors.mono0,
  '--mono100': colors.mono100,
  '--mono300': colors.mono300,
  '--mono400': colors.mono400,
  '--mono500': colors.mono500,
  '--mono700': colors.mono700,
  '--mono900': colors.mono900,
};
