import breakpoints from '!!@homebase/sass-variable-loader!./_breakpoints.scss';

const convertPixelValueToNumber = (pixels: string): number =>
  parseInt(pixels.replace('px', ''), 10);

export const breakpointNumericValues: { [key: string]: number } = {
  small: convertPixelValueToNumber(breakpoints.smallBreakpoint),
  medium: convertPixelValueToNumber(breakpoints.mediumBreakpoint),
  mediumLarge: convertPixelValueToNumber(breakpoints.mediumLargeBreakpoint),
  large: convertPixelValueToNumber(breakpoints.largeBreakpoint),
  xlarge: convertPixelValueToNumber(breakpoints.xlargeBreakpoint),
  xxlarge: convertPixelValueToNumber(breakpoints.xxlargeBreakpoint),
};

export default breakpoints;
