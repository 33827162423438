module.exports = {
  "none": "0",
  "tiny": "1px",
  "xxxxs": "2px",
  "xxxs": "3px",
  "xxs": "7px",
  "xs": "10px",
  "sm": "15px",
  "md": "20px",
  "lg": "25px",
  "xl": "30px",
  "xxl": "40px",
  "xxxl": "60px",
  "borderWidth": "$tiny",
  "borderRadius": "3px",
  "defaultFormWidth": "500px",
  "defaultFooterHeight": "60px",
  "checkboxOffset": "$xl + $xxs",
  "messengerWidth": "360px",
  "messengerHeaderHeight": "47px",
  "messengerControlInputWidth": "200px",
  "employeeFormViewPadding": "$xs $xxl",
  "defaultMaxWidth": "1100px",
  "navHeight": "48px",
  "topbannerNavHeight": "75px",
  "messengerNavHeight": "58px",
  "messageDrawerOffset": "$nav-height + $messenger-nav-height",
  "userReferralsBoxContentWidth": "260px",
  "userReferralsBoxContentHeight": "278px",
  "sidebarWidth": "48px",
  "s0": "0",
  "s1": "1px",
  "s2": "2px",
  "s4": "4px",
  "s8": "8px",
  "s12": "12px",
  "s16": "16px",
  "s20": "20px",
  "s24": "24px",
  "s32": "32px",
  "s36": "36px",
  "s48": "48px",
  "s64": "64px"
};