module.exports = {
  "cursive": "CedarvilleCursive-Regular",
  "fw300": "300",
  "fw400": "400",
  "fw500": "500",
  "fw600": "600",
  "fw700": "700",
  "fw800": "800",
  "fs10": "10px",
  "fs12": "12px",
  "fs14": "14px",
  "fs16": "16px",
  "fs18": "18px",
  "fs24": "24px",
  "fs30": "30px",
  "fs32": "32px",
  "fs36": "36px",
  "fs40": "40px",
  "fs50": "50px",
  "baseFontFamily": "\"Plus Jakarta Sans\", sans-serif",
  "baseFontWeight": "$fw500",
  "baseFontSize": "$fs12",
  "baseLineHeight": "1.4",
  "fixedFontFamily": "monospace",
  "fixedFontSize": "85%",
  "fixedLineHeight": "$base-line-height",
  "headerFontWeight": "$fw700",
  "header": "$header-font-weight",
  "caveat": "\"Caveat\", cursive"
};