module.exports = {
  "pinkPale": "#f1ecff",
  "lilac": "#f1ecff",
  "primary050": "#f1ecff",
  "violetLight": "#cdc0f3",
  "purplePinkLight": "#cdc0f3",
  "fiolet": "#cdc0f3",
  "purpleLight": "#cdc0f3",
  "purple400": "#9c7edb",
  "primaryA3": "#7e3dd4",
  "pink": "#7e3dd4",
  "purplePinkMedium": "#7e3dd4",
  "primaryA1": "#7e3dd4",
  "primaryA2": "#52258f",
  "purpleDark": "#52258f",
  "violetDark": "$purple-light",
  "purple": "#52258f",
  "purpleXdark": "#1e0b3a",
  "aqua": "#11ada2",
  "irisBlue": "#11ada2",
  "blueDark": "#037a72",
  "white": "#fff",
  "ghostWhite": "#fff",
  "blueDuckEgg": "#f2f2ec",
  "grayLight": "#f2f2ec",
  "gray94": "#f2f2ec",
  "concreteGrey": "#f2f2ec",
  "grayMediumLight": "#e6e4d6",
  "santorini": "#e6e4d6",
  "grayMedium": "#e6e4d6",
  "monoGray": "#9e9c88",
  "neutral300": "#9e9c88",
  "gray77": "#9e9c88",
  "neutral500": "#605f56",
  "grayXdark": "#605f56",
  "monoDark": "#605f56",
  "grayDark": "#605f56",
  "sealGrey": "#605f56",
  "brightGrey": "#605f56",
  "neutral600": "#605f56",
  "neutral700": "#605f56",
  "navyLight": "#605f56",
  "darkGray": "#605f56",
  "eerieBlack": "#1e0b3a",
  "oxfordBlue": "#1e0b3a",
  "navy": "#1e0b3a",
  "primaryText": "#1e0b3a",
  "greenLightest": "#e5f4e7",
  "greenLight": "#028810",
  "green": "#028810",
  "lime": "#028810",
  "greenDark": "#155e34",
  "frostedMint": "#e9f7ff",
  "blueLightest": "#e9f7ff",
  "secondaryA2": "#e9f7ff",
  "blue": "#0177b0",
  "bluePaymentsHub": "#0177b0",
  "b500": "#0177b0",
  "blueLight": "#0177b0",
  "blueMedium": "#0177b0",
  "blueSky": "#0177b0",
  "blue2": "#005781",
  "blueSteel": "#005781",
  "blueRoyal": "#005781",
  "blueFacebook": "#005781",
  "yellowMediumDark": "#fdf3e6",
  "yellowLight": "#fdf3e6",
  "yellowMedium": "#fdf3e6",
  "shift01": "#fdf3e6",
  "yellow": "#fdf3e6",
  "yellowDark": "#eb7f00",
  "shift02": "#eb7f00",
  "orangeYellow": "#eb7f00",
  "orange": "#eb7f00",
  "orangeDark": "#c0490a",
  "orangePayAnywhere": "#c0490a",
  "alertA1": "#fff0ee",
  "orangeLight": "#fff0ee",
  "pinkLight": "#fff0ee",
  "orangeIndeed": "#d72505",
  "orangeIndeedDark": "#d72505",
  "red": "#d72505",
  "redOrange": "#d72505",
  "red3": "#a32100",
  "redWarning": "#a32100",
  "red2": "#a32100",
  "borderColor": "$gray-medium-light",
  "errorColor": "$red",
  "whiteMedium": "$gray-light",
  "blackLight": "$navy",
  "black": "$navy",
  "grayLightest": "$gray-light",
  "grayMediumLighter": "$gray-medium-light",
  "grayMediumDark": "$gray-dark",
  "redMedium": "$red",
  "navyDark": "$navy",
  "navyMedium": "$navy-dark",
  "purpleLighter": "$purple-light",
  "purpleMedium": "$purple",
  "violet": "$purple-light",
  "violetMedium": "$purple-light",
  "violetXdark": "$purple-dark"
};