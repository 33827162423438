module.exports = {
  "purple100": "#f1ecff",
  "purple300": "#cdc0f3",
  "purple400": "#9c7edb",
  "purple500": "#7e3dd4",
  "purple700": "#52258f",
  "purple900": "#1e0b3a",
  "accent100": "#f7c7ee",
  "accent500": "#e55ccd",
  "accent900": "#a92c93",
  "highlight500": "#e0ff00",
  "teal100": "#e4f6f6",
  "teal200": "#abddda",
  "teal300": "#11ada2",
  "teal500": "#037a72",
  "mono0": "#fff",
  "mono100": "#f2f2ec",
  "mono300": "#e6e4d6",
  "mono400": "#c0bda2",
  "mono500": "#9e9c88",
  "mono700": "#605f56",
  "mono900": "#1e0b3a",
  "green100": "#e5f4e7",
  "green300": "#028810",
  "green500": "#155e34",
  "blue100": "#e9f7ff",
  "blue300": "#0177b0",
  "blue500": "#005781",
  "orange100": "#fdf3e6",
  "orange300": "#eb7f00",
  "orange500": "#c0490a",
  "red100": "#fff0ee",
  "red300": "#d72505",
  "red500": "#a32100",
  "red900": "#5d1300"
};