module.exports = {
  "pinkPale": "#f2fcee",
  "lilac": "#f2fcee",
  "primary050": "#f2fcee",
  "violetLight": "#bee4b2",
  "purplePinkLight": "#bee4b2",
  "fiolet": "#bee4b2",
  "purpleLight": "#bee4b2",
  "purple400": "#8bd174",
  "primaryA3": "#280",
  "pink": "#280",
  "purplePinkMedium": "#280",
  "primaryA1": "#280",
  "primaryA2": "#276a16",
  "purpleDark": "#276a16",
  "violetDark": "$purple-light",
  "purple": "#276a16",
  "purpleXdark": "#000",
  "aqua": "#11ada2",
  "irisBlue": "#11ada2",
  "blueDark": "#037a72",
  "white": "#fff",
  "ghostWhite": "#fff",
  "blueDuckEgg": "#f7f9fb",
  "grayLight": "#f7f9fb",
  "gray94": "#f7f9fb",
  "concreteGrey": "#f7f9fb",
  "grayMediumLight": "#e5e5e5",
  "santorini": "#e5e5e5",
  "grayMedium": "#e5e5e5",
  "monoGray": "#c7c7c7",
  "neutral300": "#c7c7c7",
  "gray77": "#c7c7c7",
  "neutral500": "#5c5c5c",
  "grayXdark": "#5c5c5c",
  "monoDark": "#5c5c5c",
  "grayDark": "#5c5c5c",
  "sealGrey": "#5c5c5c",
  "brightGrey": "#5c5c5c",
  "neutral600": "#5c5c5c",
  "neutral700": "#5c5c5c",
  "navyLight": "#5c5c5c",
  "darkGray": "#5c5c5c",
  "eerieBlack": "#000",
  "oxfordBlue": "#000",
  "navy": "#000",
  "primaryText": "#000",
  "greenLightest": "#f2fcee",
  "greenLight": "#bee4b2",
  "green": "#bee4b2",
  "lime": "#bee4b2",
  "greenDark": "#280",
  "frostedMint": "#eefbfc",
  "blueLightest": "#eefbfc",
  "secondaryA2": "#eefbfc",
  "blue": "#69d0dd",
  "bluePaymentsHub": "#69d0dd",
  "b500": "#69d0dd",
  "blueLight": "#69d0dd",
  "blueMedium": "#69d0dd",
  "blueSky": "#69d0dd",
  "blue2": "#286271",
  "blueSteel": "#286271",
  "blueRoyal": "#286271",
  "blueFacebook": "#286271",
  "yellowMediumDark": "#fff6cc",
  "yellowLight": "#fff6cc",
  "yellowMedium": "#fff6cc",
  "shift01": "#fff6cc",
  "yellow": "#fff6cc",
  "yellowDark": "#eb7f00",
  "shift02": "#eb7f00",
  "orangeYellow": "#eb7f00",
  "orange": "#eb7f00",
  "orangeDark": "#c0490a",
  "orangePayAnywhere": "#c0490a",
  "alertA1": "#ffeded",
  "orangeLight": "#ffeded",
  "pinkLight": "#ffeded",
  "orangeIndeed": "#c60000",
  "orangeIndeedDark": "#c60000",
  "red": "#c60000",
  "redOrange": "#c60000",
  "red3": "#a32100",
  "redWarning": "#a32100",
  "red2": "#a32100",
  "borderColor": "$gray-medium-light",
  "errorColor": "$red",
  "whiteMedium": "$gray-light",
  "blackLight": "$navy",
  "black": "$navy",
  "grayLightest": "$gray-light",
  "grayMediumLighter": "$gray-medium-light",
  "grayMediumDark": "$gray-dark",
  "redMedium": "$red",
  "navyDark": "$navy",
  "navyMedium": "$navy-dark",
  "purpleLighter": "$purple-light",
  "purpleMedium": "$purple",
  "violet": "$purple-light",
  "violetMedium": "$purple-light",
  "violetXdark": "$purple-dark"
};