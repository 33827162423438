module.exports = {
  "purple100": "#f2fcee",
  "purple300": "#bee4b2",
  "purple400": "#8bd174",
  "purple500": "#280",
  "purple700": "#276a16",
  "purple900": "#000",
  "accent100": "$purple100",
  "accent500": "$purple500",
  "accent900": "$purple700",
  "highlight500": "$purple500",
  "teal100": "#e4f6f6",
  "teal200": "#abddda",
  "teal300": "#11ada2",
  "teal500": "#037a72",
  "mono0": "#fff",
  "mono100": "#f7f9fb",
  "mono300": "#e5e5e5",
  "mono400": "#c7c7c7",
  "mono500": "#c7c7c7",
  "mono700": "#5c5c5c",
  "mono900": "#000",
  "green100": "$purple100",
  "green300": "$purple300",
  "green500": "$purple500",
  "blue100": "#eefbfc",
  "blue300": "#69d0dd",
  "blue500": "#286271",
  "orange100": "#fff6cc",
  "orange300": "#eb7f00",
  "orange500": "#c0490a",
  "red100": "#ffeded",
  "red300": "#c60000",
  "red500": "#a32100",
  "red900": "#5d1300"
};