module.exports = {
  "zindexBottomDrawer": "1000",
  "zindexFdbDialog": "1300",
  "zindexFdbPopper": "1300",
  "zindexFdbDropdown": "1300",
  "zindexFdbContextMenu": "1300",
  "zindexFdbToast": "1400",
  "zindexFdbTooltip": "1500",
  "zindexFdbReactModal": "10000",
  "zindexMessengerViewMenu": "111"
};